// <ui-alert dismissable="true" (onDismiss)="doSomething()" type="alert_type">
//     optional: <i class="some_icon"><i> or <img src="some_img" height="someHeight" />
//     minimal: Some text describing the alert
//     optional: <button ui-button class="some_type_of_button">CTA</button>
// </ui-alert>
// Available types: primary, warning, danger, success. When none is set it will go in default mode.

.alert {
    @include flexbox;
    @include flex-flow(row wrap);
    @include align-items(flex-start);
    padding: map-get($alert, padding) - $spas;
    border-radius: map-get($alert, border-radius);
    font-size: map-get($alert, font-size);
    border-width: map-get($alert, border-width);
    border-style: solid;

    // default state, when no type is defined
    &.default {
        background: map-get($alert, default, background);
        border-color: map-get($alert, default, border-color);
    }

    .alert-thumb, .alert-details, .alert-actions {
        margin: $spas;
    }

    .alert-details {
        @include flex( 1 1 0%);
        color: inherit;
        width: 100%;
        overflow: hidden;

        p { margin: 0; color: inherit; }        
    }

    .alert-close {
        color: inherit;
        width: map-get($button, height);
        padding: 0;
        margin-right: - $spas;
    }

    .alert-actions {
        white-space: nowrap;
    }

    @include breakpoint_to(sm) {
        .alert-details, .alert-actions {
            order: 12;
            flex-basis: 100%;
        }

        .alert-close { margin-left: auto; }
    }

    /// alert color, background and border color
    @each $color in map-get($alert, types) {
        &.#{$color} {
            background: theme-color(--#{$color}-5);
            border-color: theme-color(--#{$color}-10);
            color: theme-color(--shade);

            a { color: inherit;}
        }
    }
}